

// 状态 {name, type}
export const statusOpts = [
  {
    name: '活跃的',
    id: 'ACTIVE',
  },
  {
    name: '暂停的',
    id: 'PAUSED'
  }
]

// 更改类型 {name, type}
export const changeTypeOpts = [
  {
    name: '提高出价',
    id: 'INCREASE'
  },
  {
    name: '降低出价',
    id: 'DECREASE'
  },
  {
    name: 'Enabled',
    id: 'Enabled'
  },
  {
    name: 'Paused',
    id: 'Paused'
  },
]

// 广告活动的类型 {name, type}
export const campaignTypeOpts = [
  {
    name: '商品推广广告 - 关键词',
    id: 'SP_keyword'
  },
  {
    name: '品牌广告SB - 关键词',
    id: 'SB_keyword'
  },
  {
    name: '展示型广告SD - 关键词',
    id: 'SD_keyword'
  },
  {
    name: '商品推广广告 - 产品',
    id: 'SP_product'
  },
  {
    name: '品牌广告 - 产品投放',
    id: 'SB_product'
  },
  {
    name: '展示型广告 - 产品投放',
    id: 'SD_product'
  },
  {
    name: 'Sponsored TV - Product',
    id: 'ST_product'
  },
  {
    name: '商品推广广告 - 自动',
    id: 'SP_auto'
  },
]

export const targetOpt = [
  {
    name: '关键词',
    id: 'keyword'
  },
  {
    name: 'ASIN的',
    id: 'product'
  },
]

export const filterKeys = [
  'acos_14d', 'roas_14d', 'sum_clicks', 'amt_ad_direct_14d', 'cpc', 'sum_impressions', 'cvr_14d', 'units_sold_14d', 'sum_spend', 'ctr', 'cnt_ad_14d', 'modify_time',
]

export const filterPercentKeys = [
  'acos_14d', 'target_acos', 'cvr_14d', 'ctr'
]
