import { allHttpRequest } from 'src/utils/httpRequest';

// 获取我的建议列表
export const getMyOrderList = async (filter={}) => {
  const res = await allHttpRequest.post('/llm_server/pay/order/list', filter);
  return res;
};

// 取消支付
export const cancelMyOrder = async (order_id: string) => {
  const res = await allHttpRequest.post(`/llm_server/pay/order/cancel/${order_id}`);
  return res;
};

// 获取过期时间
export const getMySubscript = async () => {
  const res = await allHttpRequest.get('/llm_server/pay/my_subscription');
  return res;
};
