import { allHttpRequest } from 'src/utils/httpRequest';

// 获取建议列表
export const createOrder = async (filter: object = {}) => {
  const res = await allHttpRequest.post('/llm_server/pay/create_order', {
    "order_params": filter
  });
  return res;
};

// 获取建议列表
export const getOrderStatus = async (order_id: string) => {
  const res = await allHttpRequest.get(`/llm_server/pay/query_order_status/${order_id}`);
  return res;
};
