/* eslint-disable theme-colors/no-literal-colors */
// import qrcode from 'qrcode';
import React, { memo, useEffect, useState } from 'react';
import URI from 'urijs';
import Loading from 'src/components/Loading';
import { Result, Button, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import { WechatOutlined } from '@ant-design/icons';
import DatawakeIcon from 'src/assets/images/favicon.png';
import styles from './index.module.less';
import { formatPrice } from '../Adtopic/component/RightContent/Suggestion/utils';
import { getOrderStatus } from './api';

export const transformMoney = (money: string) => {
  const ml = money.length;

  if (ml === 1) return `0.0${money}`;
  if (ml === 2) return `0.${money}`;

  if (ml > 2) {
    const mLeft = money.slice(0, -2);
    const mRight = money.slice(-2);
    
    return `${mLeft}.${mRight}`;
  };
}

const OrderInfo: React.FC = () => {

  const [isPaySuccess, setIsPaySuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderStatus, setOrderStatus] = useState('');
  const location = useLocation();
  const queryRes = URI.parseQuery(decodeURIComponent(location.search));
  
  const [listenOrderStatus, setListenOrderStatus] = useState(true);
  const history = useHistory();

  const imageSettings = {
    src: DatawakeIcon,
    height: 24,
    width: 24,
    opacify: 1,
    excavate: true,
  }

  const requestOrderStatus = (callback: (status: string) => void, errorCallback: () => void) => {
    getOrderStatus(queryRes.order_id).then((res) => {
      const {status} = res.json.ret;
      if (status === 'PROCESSING' || status === 'FINISHED') {
        const t = status === 'PROCESSING' ? '已经支付成功，后台正在处理订单...' : '订单已处理完成';
        setOrderStatus(t);
        setIsPaySuccess(true);
        setListenOrderStatus(false);
      }
      callback(status);
    }).catch(() => {
      message.error('支付遇到问题，请联系客服人员')
      errorCallback();
    })
  }

  useEffect(() => {
    let timer: any = null;
    requestOrderStatus((status) => {
      if (status !== 'FINISHED') {
        timer = setInterval(() => {
          if (listenOrderStatus) {
            requestOrderStatus((st) => {
              if(st === 'FINISHED') {
                clearInterval(timer);
              }
            }, () => clearInterval(timer));
          }
        }, 5000);
      }
      setLoading(false);
    }, () => {});

    return () => {
      clearInterval(timer);
    }
  }, []);

  const toOrderList = () => {
    history.push('/user/info/?id=4');
  }
  
  return (
          <div className={styles.order_container}>
            <div className={styles.order_title}>
              订单详情
            </div>

            <div className={styles.order_detail}>
              <div className={styles.order_number}>
                订单号：{queryRes.order_id}
              </div>
              <div className={styles.order_head}>
                <div>订单描述：
                  <span className={styles.order_desc}>{queryRes.description}</span>
                </div>
                <span className={styles.order_price}>￥{formatPrice(transformMoney(queryRes.amount))}</span>
              </div>
            </div>
            {
              loading
                ?
                  (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Loading position="normal" />
                    </div>
                  )
                : isPaySuccess ?
                  <Result
                    status="success"
                    title={orderStatus}
                    extra={[
                      <Button 
                        type="primary" 
                        className={styles.adtomic_btn} 
                        key="buy"
                        onClick={toOrderList}
                      >
                        查看订单
                      </Button>,
                    ]}
                  /> :
                    (
                      <div className={styles.order_pay_zone}>
                        <div className={styles.order_pay_way}>
                          <span className={styles.order_pay_way_desc}>
                            请使用微信扫码支付
                          </span>
                          <WechatOutlined style={{color: '#00e968',fontSize: '28px'}} />
                        </div>
                        <div className={styles.order_qrcode}>
                          <QRCodeSVG size={200} level='H' imageSettings={imageSettings} value={queryRes.code_url} />
                        </div>
                        <div className={styles.order_error}>
                          完成扫描支付后，若没有自动跳转，可以联系客服
                        </div>
                      </div>
                    )
            }
          </div>
        )}
;

export default memo(OrderInfo);
